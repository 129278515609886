* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.select2.select2-container.select2-container--default {
  width: 100% !important;
}

.select2-selection__arrow {
  height: 100% !important;
  top: 0;
}

label {
  display: block;
  margin: 15px 0 8px;
}

input, .select2-search__field, .select2-selection.select2-selection--single {
  border-radius: 0 !important;
  display: block;
  width: 100%;
  height: 38px !important;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input:focus, .select2-search__field:focus, .select2-selection.select2-selection--single:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

p, li {
  line-height: 1.4em;
  font-size: 16px;
}

.hide {
  display: none !important;
}

#signup-block {
  background-color: #fa9016;
  color: #fff;
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
}

#signup-block video {
  max-width: 100%;
}

#signup-block .col.form {
  background-image: url("dishes-bg.89442e13.png");
  flex: 1;
  background-size: cover;
  padding: 64px 80px 96px 80px;
}

#signup-block .col.form h2 {
  text-align: center;
  margin-bottom: 8px;
}

#signup-block .col {
  padding: 48px 15px;
  flex: 1;
  margin: 0 15px;
}

#signup-block p {
  margin: 24px 0;
}

#signup-block .logo {
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
}

#signup-block #small_form, #signup-block #big_form, #signup-block #thankyou {
  max-width: 400px;
  margin: 0 auto;
}

#signup-block #big_form .row {
  display: flex;
  align-items: flex-end;
}

#signup-block #big_form .row .col-left {
  padding-right: 4px;
  flex: 1;
}

#signup-block #big_form .row .col-right {
  padding-left: 4px;
  flex: 1;
}

#signup-block #big_form .row button {
  padding: 0;
  margin: 0 0 0 8px;
  height: 38px;
  width: 38px;
  text-align: center;
}

#signup-block #big_form .row button img {
  margin: 0;
}

.btn {
  display: inline-block;
  font-family: Montserrat, Sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 15px 30px;
  border-radius: 0;
  line-height: 1.5;
  margin-top: 24px;
  cursor: pointer;
}

.btn:disabled {
  opacity: 0.65;
}

.btn-100 {
  width: 100%;
}

.btn-primary, .btn-secondary, .btn-white {
  border: 1px solid transparent;
}

.btn-primary img, .btn-secondary img, .btn-white img {
  display: inline-block;
  height: 16px;
  margin-left: 8px;
  fill: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #23408e;
  border-color: #23408e;
}

.btn-secondary {
  color: #fff;
  background-color: #fa9016;
  border-color: #fa9016;
}

.btn-clear {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-color: #fff;
}

.btn-white {
  color: #23408e;
  background-color: #fff;
  border: 1px solid #fff;
  border-color: #fff;
}

.terms_and_conditions {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
}

.terms_and_conditions input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.terms_and_conditions a {
  color: #fa9016;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

.terms_and_conditions:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.terms_and_conditions input:checked ~ .checkmark {
  background-color: #23408e;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.terms_and_conditions input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.terms_and_conditions .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

canvas {
  position: relative;
  margin: 1px;
  margin-left: 0px;
  border: 1px solid #3a87ad;
  max-width: 100%;
}

#controlPanel {
  margin: 2px;
}

#saveSignature {
  display: none;
}

#benefits-block {
  width: 1140px;
  max-width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

#benefits-block .row {
  flex: 1;
  display: flex;
  padding: 16px 0;
}

#benefits-block .row h2 {
  color: #23408e;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  padding: 16px 0;
}

#benefits-block .row .col {
  padding: 15px;
  color: #6c757d;
  font-size: 16px;
}

#benefits-block .row .col:hover img {
  filter: brightness(100%) contrast(120%) saturate(100%) blur(0px) hue-rotate(0deg);
  opacity: 1;
}

#benefits-block .row .col img {
  width: 53px;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
  opacity: 0.7;
  transition-duration: 0.5s;
}

#video-block {
  color: #fff;
  background: linear-gradient(180deg, #23408e, #f88903);
  margin: 48px 0 0 0;
  padding: 48px 15px 64px 15px;
  text-align: center;
}

#video-block .col {
  max-width: 800px;
  margin: 0 auto;
}

#video-block h1 {
  padding: 48px 15px 0 15px;
  font-size: 50px;
  font-weight: 400;
}

#video-block p {
  line-height: 18.4px;
  padding: 16px 0;
}

#info-block {
  color: #23408e;
  padding: 48px 15px;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}

#info-block .col {
  text-align: center;
  padding: 15px;
  flex: 1;
}

#info-block .col .number {
  font-size: 60px;
}

#info-block .col .text {
  font-size: 16px;
}

.mfr-logos {
  width: 100%;
}

.mfr-logo img {
  width: 100%;
  vertical-align: center;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

#earn-block, #access-block {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  padding: 48px 15px;
}

#earn-block .col, #access-block .col {
  padding: 15px 15px 48px 15px;
  flex: 1;
}

#earn-block .col.video, #earn-block .col.access, #access-block .col.video, #access-block .col.access {
  align-items: center;
  display: flex;
  padding: 15px;
}

#earn-block .col.video img, #earn-block .col.access img, #access-block .col.video img, #access-block .col.access img {
  max-width: 100%;
}

#earn-block .col.video, #access-block .col.video {
  max-width: 730px;
  width: 50%;
  justify-content: center;
  background-image: url("earn-money-bg.20cd71d8.png");
  flex-direction: column;
}

#earn-block .col.access, #access-block .col.access {
  max-width: fit-content;
}

#earn-block .col h2, #access-block .col h2 {
  color: #23408e;
  font-size: 40px;
  line-height: 52px;
}

#earn-block .col p, #earn-block .col li, #access-block .col p, #access-block .col li {
  color: #6c757d;
}

#earn-block .col p, #access-block .col p {
  margin-top: 24px;
  font-size: 16px;
}

#programs-block {
  background-color: #23408e;
  padding: 48px 15px;
  color: #fff;
}

#programs-block .container {
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
}

#programs-block .col {
  padding: 15px 15px 48px 15px;
  flex: 1;
}

#programs-block .col.programs {
  text-align: center;
  align-items: center;
  display: flex;
  padding: 15px;
  justify-content: center;
}

#programs-block .col.programs img {
  max-width: 100%;
}

#programs-block .col h2 {
  font-size: 40px;
  line-height: 52px;
}

#programs-block .col p {
  margin-top: 24px;
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  #signup-block {
    flex-direction: column;
  }
  #signup-block .col.form {
    padding: 32px 15px 96px 15px;
  }

  #info-block, #earn-block, #programs-block .container, #access-block {
    flex-direction: column;
  }

  #benefits-block {
    flex-direction: column;
  }
}
/*# sourceMappingURL=index.7a6aba9b.css.map */
