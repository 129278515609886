$primary: #23408e;
$secondary: #fa9016;
$muted: #6c757d;

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.select2.select2-container.select2-container--default {
  width: 100%!important;
}

.select2-selection__arrow {
  height: 100%!important;
  top: 0;
}

label {
  display: block;
  margin: 15px 0 8px;
}

input, .select2-search__field, .select2-selection.select2-selection--single {
  border-radius: 0!important;
  display: block;
  width: 100%;
  height: 38px!important;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da!important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
}

p, li {
  line-height: 1.4em;
  font-size: 16px;
}

.hide {
  display: none!important;
}

#signup-block {
  background-color: $secondary;
  color: #fff;
  flex-wrap: wrap;
  display: flex;
  max-width: 100%;
  video {
    max-width: 100%;
  }
  .col.form {
    background-image: url("./assets/dishes-bg.png");
    flex: 1;
    background-size: cover;
    padding: 64px 80px 96px 80px;
    h2 {
      text-align: center;
      margin-bottom: 8px;
    }
  }
  .col {
    padding: 48px 15px;
    flex: 1;
    margin: 0 15px;
  }
  p {
      margin: 24px 0;
    }
  .logo {
    max-width: 350px;
    width: 100%;
    margin-bottom: 40px;
  }
  #small_form, #big_form, #thankyou {
    max-width: 400px;
    margin: 0 auto;
  }
  #big_form {
    .row {
      display: flex;
      align-items: flex-end;
      .col {
        &-left {
          padding-right: 4px;
          flex: 1;
        }
        &-right {
          padding-left: 4px;
          flex: 1;
        }
      }
      button {
        padding: 0;
        margin: 0 0 0 8px;
        height: 38px;
        width: 38px;
        text-align: center;
        img {
          margin: 0;
        }
      }
    }
  }
}

.btn {
  display: inline-block;
  font-family: Montserrat,Sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 15px 30px;
  border-radius: 0;
  line-height: 1.5;
  margin-top: 24px;
  cursor: pointer;
  &:disabled {
    opacity: 0.65;
  }
  &-100 {
    width: 100%;
  }
  &-primary, &-secondary, &-white {
    border: 1px solid transparent;
    img {
      display: inline-block;
      height: 16px;
      margin-left: 8px;
      fill: #fff;
    }
  }
  &-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  &-secondary {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
  }
  &-clear {
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-color: #fff;
  }
  &-white {
    color: $primary;
    background-color: #fff;
    border: 1px solid #fff;
    border-color: #fff;
  }
}

.terms_and_conditions {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  a {
    color: $secondary;
  }
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.terms_and_conditions:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.terms_and_conditions input:checked ~ .checkmark {
  background-color: $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.terms_and_conditions input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.terms_and_conditions .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
canvas {
  position: relative;
  margin: 1px;
  margin-left: 0px;
  border: 1px solid #3a87ad;
  max-width: 100%;
}
#controlPanel {
  margin: 2px;
}
#saveSignature {
  display: none;
}

#benefits-block {
  width: 1140px;
  max-width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  .row {
    flex: 1;
    display: flex;
    padding: 16px 0;
    h2 {
      color: $primary;
      font-size: 22px;
      font-weight: 700;
      line-height: 26.4px;
      padding: 16px 0;
    }
    .col {
      padding: 15px;
      color: $muted;
      font-size: 16px;
      &:hover img {
        filter: brightness(100%) contrast(120%) saturate(100%) blur(0px) hue-rotate(0deg);
        opacity: 1;
      }
      img {
        width: 53px;
        filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
        opacity: .7;
        transition-duration: .5s;
      }
    }
  }
}

#video-block {
  color: #fff;
  background: linear-gradient(180deg,#23408e,#f88903);
  margin: 48px 0 0 0;
  padding: 48px 15px 64px 15px;
  text-align: center;
  .col {
    max-width: 800px;
    margin: 0 auto;
  }
  h1 {
    padding: 48px 15px 0 15px;
    font-size: 50px;
    font-weight: 400;
  }
  p {
    line-height: 18.4px;
    padding: 16px 0;
  }
}

#info-block {
  color: $primary;
  padding: 48px 15px;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  .col {
    text-align: center;
    padding: 15px;
    flex: 1;
    .number {
      font-size: 60px;
    }
    .text {
      font-size: 16px;
    }
  }
}

.mfr-logos {
  width: 100%;
}

.mfr-logo img {
  width: 100%;
  vertical-align: center;
}
.slick-track {
  display: flex!important;
  align-items: center;
}

#earn-block, #access-block {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  padding: 48px 15px;
  .col {
    &.video, &.access {
      align-items: center;
      display: flex;
      padding: 15px;
      img {
        max-width: 100%;
      }
    }
    &.video {
      max-width: 730px;
      width: 50%;
      justify-content: center;
      background-image: url("./assets/earn-money-bg.png");
      flex-direction: column;
    }
    &.access {
      max-width: fit-content;
    }
    padding: 15px 15px 48px 15px;
    flex: 1;
    h2 {
      color: $primary;
      font-size: 40px;
      line-height: 52px;
    }
    p, li {
      color: $muted;
    }
    p {
      margin-top: 24px;
      font-size: 16px;
    }
  }
}

#programs-block {
  background-color: $primary;
  padding: 48px 15px;
  color: #fff;
  .container {
    max-width: 1140px;
    display: flex;
    margin: 0 auto;
  }
  .col {
    &.programs {
      text-align: center;
      align-items: center;
      display: flex;
      padding: 15px;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
    padding: 15px 15px 48px 15px;
    flex: 1;
    h2 {
      font-size: 40px;
      line-height: 52px;
    }
    p {
      margin-top: 24px;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 800px) {
  #signup-block {
    flex-direction: column;
    .col.form {
      padding: 32px 15px 96px 15px;
    }
  }
  #info-block, #earn-block, #programs-block .container, #access-block {
    flex-direction: column;
  }
  #benefits-block {
    flex-direction: column;
  }
}
